<template>
  <div class="adsItem">
    <div ref="adContainer" class="ad-double-container" />
    <div v-if="showDebug" class="ad-msg">
      {{ adType }}_{{ adPosition }} = {{ getAdConfig() }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    adPosition: {
      type: Number,
      required: true
    },
    adType: {
      type: String,
      required: true
    },
  },
  computed: {
    ...mapGetters({
      adsense: "adsenseConfig",
      showDebug: "showDebug"
    })
  },
  mounted () {
    this.insertAdScripts();
    // 使用获取到的值
    // const { maxWidth, maxHeight } = this.getContainerMaxDimensions();
    // console.log('Max Width:', maxWidth, 'Max Height:', maxHeight);
  },
  methods: {
    insertAdScripts () {
      const adConfig = this.getAdConfig();
      console.log(adConfig);

      // 提取 min-width 和 min-height
      const minWidthMatch = adConfig.bodyScript.match(/min-width:\s*(\d+)px;/);
      const minHeightMatch = adConfig.bodyScript.match(/min-height:\s*(\d+)px;/);

      const minWidth = minWidthMatch ? parseInt(minWidthMatch[1], 10) : 0;
      const minHeight = minHeightMatch ? parseInt(minHeightMatch[1], 10) : 0;

      console.log('Min Width:', minWidth, 'Min Height:', minHeight);

      // 计算宽高比
      let aspectRatio = 1; // 默认值
      if (minWidth > 0 && minHeight > 0) {
        aspectRatio = minHeight / minWidth;
      }

      // 设置广告容器的初始样式
      const adContainer = this.$refs.adContainer;
      adContainer.style.width = '100%';
      adContainer.style.height = 'auto'; // 先设置为 auto

      // 插入广告脚本
      if (adConfig.headScript) {
        const head = document.head || document.getElementsByTagName('head')[0];
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.text = adConfig.headScript;
        head.appendChild(script);
      }

      if (adConfig.bodyScript) {
        adContainer.innerHTML = adConfig.bodyScript;

        // 处理嵌入的脚本
        const scripts = adContainer.getElementsByTagName('script');
        for (let script of scripts) {
          const newScript = document.createElement('script');
          newScript.type = 'text/javascript';
          newScript.text = script.text;
          document.body.appendChild(newScript);
        }
      }

      // 在 DOM 更新后计算并设置高度
      this.$nextTick(() => {
        const actualWidth = adContainer.offsetWidth;
        const calculatedHeight = actualWidth * aspectRatio;
        adContainer.style.maxHeight = `${calculatedHeight}px`;
        console.log('Actual Width:', actualWidth, 'Calculated Height:', calculatedHeight);
      });
    },
    getAdConfig () {
      console.log(`${this.adType}_${this.adPosition}`);
      return this.adsense[`${this.adType}_${this.adPosition}`] || {};
    },
  }
}
</script>

